import React from 'react'
import { Listing } from "@aaa/muse-ds/Listing";
import { Header2 } from "@aaa/muse-ds/Header2";
import { Link} from "@aaa/muse-ds/Link";
import { Container } from "@aaa/muse-ds/Container";
import { GridColumnsContainer } from "@aaa/muse-ds/GridColumnsContainer";
import ContentTitleContainerComponent from "../ContentTitleContainerComponent";
import { getNuxeoLinkUrl } from "../../utils/nuxeo.utils";
import { useContext } from "react";
import { SEOPageDataContext } from "../../App";
import { logNavigationClick } from "../../utils/logging.utils";
import SectionHiddenIdComponent from "../special/SectionHiddenIdComponent";
import { SEOComponentContext } from "../../utils/component.utils";
import { navigateToUrl } from "../../utils/navigation.utils";

export default function WebCompListSection(props) {
  
  const background = props['background'] || { color: null };
  const { groups } = props;
  
  const pageContext = useContext(SEOPageDataContext) || {uid: ""};
  const componentContext = useContext(SEOComponentContext) || {}
  const {site,domain,preview,page} = pageContext
  const columnLayout = (
    props.columnLayout == "listingColumnWidth/threeColumnListing" ? "4" : 
    props.columnLayout == "listingColumnWidth/oneColumnListing" ? "12" : "6"
    );
  return (
    <ContentTitleContainerComponent
      background={background.color}
      content={props.content}>
      <SectionHiddenIdComponent/>
      <Container width="1366">
        {(groups||[]).map((x, i) => {

          var hrefUrl = null

          // If this group has a button
          if(x.button){
            if(x.button!=null&&x.button.docEndpoint&&x.button.docEndpoint.trim().length>0){
              x.button.endpoint=x.button.docEndpoint;
            }

            // The nuxeo url for that button
            hrefUrl=getNuxeoLinkUrl(x.button.doc,x.button.url,x.button,site,domain,preview)
            var buttonStyle = x.button.style;
            
        }
          
          return <div
            style={{ marginBottom: 54 }}
            key={props.title + ' List Section - Grid Columns Container ' + i}>
            <Header2 bold fontSize={props.listingLayout == "stacked" ? "XL" : "L"} centerText={props.listingLayout == "stacked" ? true : false}>
            {buttonStyle == "" ? x.title : (
                <Link label={x.title} href={hrefUrl}
                fontSize={props.listingLayout == "stacked" ? "XL" : "L"} 
                action = {(e)=>{

                  // Stop propagation
                  e.preventDefault();
                  e.stopPropagation();

                  // Log a navigation click
                  logNavigationClick(site,domain,page,"Listing Click: "+x.title,props.nuxeoTitle,hrefUrl)
                    .then((e)=>{
                      


                      // If we have a target location, that isn't the same page
                      // navigate to it
                      if(hrefUrl && hrefUrl!="/")navigateToUrl(hrefUrl,pageContext,componentContext);
                      
                    })
                }}/>
            )} 
          </Header2>
            <GridColumnsContainer columnS="12" columnL={columnLayout} alignVertical="top">
              {(x.items||[]).map((y, j) => (
                <Listing
                  key={
                    props.title +
                    ' List Section - Grid Columns Container ' +
                    i +
                    ' - Listing ' +
                    j
                  }
                  listingAction = {(e)=>{

                    // Stop propagation
                    e.preventDefault();
                    e.stopPropagation();

                    var hrefUrl = null

                    // If this item has a button
                    if(x.button){

                      // Get the url for that button
                      hrefUrl=getNuxeoLinkUrl(y.button.doc,y.button.url,y.button,site,domain,preview)
                    }

                    // Log a navigation click
                    logNavigationClick(site,domain,page,"Listing Click: "+y.title,props.nuxeoTitle,hrefUrl)
                    .then((e)=>{

                      // If we have a target location, that isn't the same page
                      // navigate to it
                      if(hrefUrl && hrefUrl!="/")navigateToUrl(hrefUrl,pageContext,componentContext);
                    })
                  }}
                  description={y.description}
                  detailURL={y.button?getNuxeoLinkUrl(y.button.doc,y.button.url,y.button,site,domain,preview):null}
                  stack={props.listingLayout == "stacked" ? true : false}
                  imgURL={y.image}
                  title={y.title}
                  imageSizeAuto
                />
              ))}
            </GridColumnsContainer>
          </div>
        })}
      </Container>
    </ContentTitleContainerComponent>
  );
}
