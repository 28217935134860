import { getDefaultDomain, getDefaultHost, getNuxeoEnvironmentDomain } from "./ssr.utils";

 
export function getNuxeoImage(id,width,height){
    return getNuxeoImage2(id,width,height,true)
}
export function getNonInlineNuxeoImage(id,width,height){
    return getNuxeoImage2(id,width,height,false)

}
    
export function getNuxeoImage2(id,width,height,inline){
    if(id==null)return ""

    // If this is an array
    // Map items inside the array and return an array of mapped items
    if(Array.isArray(id))return id.map(x=>getNuxeoImage(x,width,height))

    var size = []

    if(width!=null)size.push("&width="+width)
    if(height!=null)size.push("&height="+height)

    // Map the image to the nuxeo public format
    let backgroundImage = "https://"+getNuxeoEnvironmentDomain()+"/nuxeo/site/public/transform/default/"+id

    // If our image is a URL, use it directly
    if(id.includes("http://")||id.includes("https://")){
        backgroundImage = id
    }
    return backgroundImage+"?inline="+inline+"&format=webp"+size.join("")
}

export function getNuxeoLinkUrl(documentId, url,endpointMappings,site,domain,preview){

    // If no document is given
    if(documentId==null||documentId.trim().length==0){
        if(url==null)return "/"
        if(url.trim().length==0)return "/"

        // Check for a http(s) protocol in the URL
        // Prepend https if none are found
        if(url.indexOf("https://")==-1&&url.indexOf("http://")==-1){
            url = "https://"+url
        }
        return url;
    }

    // If we are in preview mode
    if(preview==true){
        return `${getDefaultHost(domain)}/?id=`+documentId.replace("default:","")
    }

    // If we were passed an object for mapping endpoints
    if(endpointMappings){


        // Look for the "endpoint" field, or a field associated with our document id
        const endpoint = endpointMappings["endpoint"]||endpointMappings[documentId];

        // If we have a valid field
        if(endpoint){

            if(site)return `${getDefaultHost(domain)}${endpoint}?site=${site}`

            return `${getDefaultHost(domain)}${endpoint}`
        }
    }
    return `${getDefaultHost(domain)}/?id=`+documentId.replace("default:","")
}
